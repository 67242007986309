<template>
  <div class="row custom-calender hidden">
    <div class="col-md-4 my-1">
      <label for="">{{ $t("listing.checkIn") }}</label>
      <div class="calendar-input-group">
        <span class="icon">
          <img src="../../assets/icons/calendar.svg" alt="calendar" />
        </span>
        <b-form-input
          readonly
          class="input check-in has-icon"
          type="text"
          v-model="date.checkIn"
          :disabled="disabled"
          id="from"
        />
      </div>
    </div>
    <div class="col-md-4 my-1">
      <label for="">{{ $t("listing.checkOut") }}</label>
      <div class="calendar-input-group">
        <span class="icon">
          <img src="../../assets/icons/calendar.svg" alt="calendar" />
        </span>
        <b-form-input
          readonly
          class="input check-out has-icon"
          type="text"
          v-model="date.checkOut"
          :disabled="disabled"
          id="to"
        />
      </div>
    </div>
    <div class="col-md-4 mt-auto my-1">
      <PrimaryButton
        @click="searchAvailability"
        :text="$t('listing.search')"
        full
        :loading="disabled"
        class="py-2 mt-3"
      />
    </div>
    <template v-if="!promoDisabled">
      <div
        v-if="!showPromoField"
        class="col-md-12 mt-3 promo cursor-pointer"
        @click="showPromoField = true"
      >
        <img src="../../assets/icons/promo.svg" class="mr-1" alt="promo" />
        <span>{{ $t("property.havePromo") }}</span>
      </div>
      <div v-else class="col-md-4 mt-3">
        <div class="calendar-input-group">
          <span class="icon promo">
            <img src="../../assets/icons/promo.svg" alt="calendar" />
          </span>
          <b-form-input
            class="input check-out has-icon"
            type="text"
            :placeholder="$t('property.promoCode')"
            v-model="promoCode"
          />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import moment from "moment";
import { mapActions } from "vuex";

import DateRangePicker from "../../helpers/datepicker/index";
export default {
  name: "BookingEngineDateSelector",
  props: {
    checkIn: {
      type: String,
      required: false,
    },
    checkOut: {
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    formatted: {
      type: Boolean,
      default: false,
    },
    promoDisabled: {
      type: Boolean,
      default: false,
    },
    currencyCode: {
      type: String,
      required: false,
    },
    currencySymbol: {
      type: String,
      required: false,
    },
    distributorType: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      datapicker: null,
      showPromoField: false,
      promoCode: "",
      date: {
        checkIn: null,
        checkOut: null,
      },
    };
  },
  mounted() {
    setTimeout(async () => {
      const { checkIn, checkOut } = this;
      if (checkIn && checkOut) {
        this.date.checkIn = this.formatted
          ? moment(checkIn, "YYYY-MM-DD").format("DD MMM YYYY")
          : moment(checkIn, "DD-MM-YYYY").format("DD MMM YYYY");
        this.date.checkOut = this.formatted
          ? moment(checkOut, "YYYY-MM-DD").format("DD MMM YYYY")
          : moment(checkOut, "DD-MM-YYYY").format("DD MMM YYYY");
      }

      this.datapicker = new DateRangePicker("#from", "#to", {
        currency: this.currencyCode,
        currencySymbol: this.currencySymbol,
        defaultFromDate: this.date.checkIn
          ? moment(this.date.checkIn, "DD MMM YYYY").toDate()
          : null,
        defaultToDate: this.date.checkOut
          ? moment(this.date.checkOut, "DD MMM YYYY").toDate()
          : null,
      });

      const startDate = this.date.checkIn
        ? moment(this.date.checkIn, "DD MMM YYYY").toDate()
        : new Date();

      if (this.distributorType === "A") {
        const priceData = await this.getPropertyCalendarPrice({
          property_id: this.$route.params.id,
          start_date: moment(startDate).format("YYYY-MM-DD"),
          end_date: moment(startDate).add("years", 1).format("YYYY-MM-DD"),
        });

        if (this.datapicker) {
          this.datapicker.setPriceData(priceData);
        }
      }
    }, 100);
  },
  methods: {
    ...mapActions("property", ["getPropertyCalendarPrice"]),
    searchAvailability() {
      const { checkIn, checkOut } = this.date;
      if (checkIn && checkOut) {
        this.$emit("search", {
          checkIn: moment(checkIn, "DD MMM YYYY").format("YYYY-MM-DD"),
          checkOut: moment(checkOut, "DD MMM YYYY").format("YYYY-MM-DD"),
          promoCode: this.promoCode,
        });
      }
    },
  },
};
</script>

<style>
@import "../../assets/css/jquery-ui.css";
@import "../../assets/css/calendar.css";
</style>
